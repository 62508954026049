import React from 'react'
import {Modal} from 'react-bootstrap';


export interface DialogBusyProps {
    visible:boolean,
    title?: string,
    message?: string,
}

const Dialog = ({visible, title='Busy', message='Please wait...'}:DialogBusyProps) => {
    return (
        <Modal show={visible} onHide={()=> {}}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img alt="Loading" src="/loading.gif" width={24} height={24}/>
                &nbsp;
                {message}
            </Modal.Body>
        </Modal>
    );
};

export default Dialog;
