import { combineReducers } from '@reduxjs/toolkit'
import accountReducer from './accounts/reducer';
import campaignsReducer from './campaigns/reducer';
import appReducer from './reducer';

const rootReducer = combineReducers({
  account: accountReducer,
  campaigns: campaignsReducer,
  app: appReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
