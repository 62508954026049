import React from 'react';
import Toast from "react-bootstrap/Toast";

export interface ToastProps {
    xid:number,
    datetime?:number,
    title:string,
    message:string, 
    delay?:number,
}

export interface ToastListProps {
    toasts:ToastProps[],
    onClose?: (xid:number)=>void,
}

const ToastList = ({toasts, onClose}:ToastListProps) => {
    let show = toasts.length > 0;
    return (
      <div style={{position:'fixed', top:0, right:0, display:show?'block':'none', zIndex:100,}}>
        {
          toasts.map((t, idx) => {
              let date = t.datetime ? new Date(t.datetime):new Date();
              let {title, message, delay=2000, xid} = t;
              const doClose = () => { onClose && onClose(xid); };
              if (delay > 0) setTimeout(doClose, delay);
            return (
              <Toast key={`toast${xid}`} 
                style={{marginBottom:0}} 
                show={show} 
                onClose={doClose}
                >
              <Toast.Header>
                <strong className="mr-auto">{title}</strong>
                <small>{date.toLocaleString()}</small>
              </Toast.Header>
              <Toast.Body>
                {message}
              </Toast.Body>
            </Toast>
            );
          })
        }
      </div>
    );
};

export default ToastList;
