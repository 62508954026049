import React, {useEffect} from 'react';
import {useSelector, } from 'react-redux';
import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';
import {Navbar, Nav, NavDropdown, } from 'react-bootstrap';
import {RootState, } from './rootReducer';


interface AppNavProps { active:string, }

const AppNav = ({active}:AppNavProps) => {
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!account.userid) {
            //history.replace('/signin');
            const hash= location.hash;
            const search = location.search;
            const path = location.pathname;
            let target = path;
            if (search) {
                target += '?' + search;
            }
            if (hash) {
                target += '#' + hash;
            }
            console.log('AppNav(): Not logined')
            navigate('/signin', {state:{target,}});
        }
    });

    const onClick = (e:any, target:string) => {
        e.preventDefault();
        //history.replace(target);
        navigate(target);
    }

    return (
        <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/" onClick={(e:any)=>{onClick(e,'/')}}>
            <img style={{marginLeft:'5px', marginRight:'5px', width:'48px', height:'48px'}} alt="logo" src="/logo192.png"/>
            <span>LITEMOMENT</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={active}>
                <Nav.Link href="/" onClick={(e:any)=>{onClick(e,'/')}}>Add Events</Nav.Link>
                <Nav.Link href="/view" onClick={(e:any)=>{onClick(e,'/view')}}>View Events</Nav.Link>
                <Nav.Link href="/tag" onClick={(e:any)=>{onClick(e,'/tag')}}>Cloud Tags</Nav.Link>
                <Nav.Link href="/shopping/checkout" onClick={(e:any)=>{onClick(e,'/shopping/checkout')}}>Shopping Mall</Nav.Link>
            </Nav>
            <Nav className="ms-auto">
                <NavDropdown title={account.username} id="basic-nav-dropdown" align={{lg: 'end'}}>
                    <NavDropdown.Item href="/resetpwd" onClick={(e:any)=>{onClick(e,'/resetpwd')}}>Change password</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/signout" onClick={(e:any)=>{onClick(e,'/signout')}}>Sign out</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    );
}

export default AppNav;
