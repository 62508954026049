import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MatchEventType = 'LITE'|'GADD'|'GSUB'|'HADD'|'HSUB'|'RESET'|'ANOTE'|'TNOTE'|'TAG'|'VAR';

export interface MatchEvent {
    eventType: MatchEventType,
    eventTS: number,
    eventAudio?: string,
    eventData?: string,
    devTS: number,
    devSN: string,
    batLevel: number,
    evtSource: number,
    evtCount: number,
}

export interface Tags {
    [group:string]:string[],
}

export interface Campaigns {
    tags: Tags,
    tagGroup: string,
}

export function createMatchEvent(init:Partial<MatchEvent>) {
    const now = Math.floor(Date.now()/1000);
    const ev:MatchEvent = {
        eventType: 'LITE',
        eventTS: now,
        devTS: now,
        devSN: window.navigator.platform,
        batLevel: 0,
        evtSource: 0,
        evtCount: 0,
    };
    Object.assign(ev, init);
    return ev;
}

const initialState:Campaigns = {tags:{}, tagGroup:'', };

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setTags(state, action: PayloadAction<Tags>) {
      const tags = action.payload;
      state.tags = tags;
    },
    setTagGroup(state, action:PayloadAction<string>) {
      const tagGroup = action.payload;
      state.tagGroup = tagGroup;
    }
  }
})

export const {
    setTags,
    setTagGroup,
} = campaignsSlice.actions

export default campaignsSlice.reducer
