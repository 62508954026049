import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Button, } from 'react-bootstrap';
// https://fontawesome.com/icons?d=gallery&p=3&s=brands,solid&m=free
import { faPlusCircle, faTimesCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../rootReducer';
import AppNav from '../AppNav';
import Const from '../Consts';
import './styles.css';
import '../styles.css';
import * as AppUtils from '../AppUtils';
import * as dataUtils from '../DataUtils';
import CloudAPI from '../CloudAPI';
import {PropsFromUIHelpers, withUIHelpers,} from '../components/hocs';


const mapState = (state:RootState) => ({});
const mapDispatch = { };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

const ORDER_ITEMS = {
    smartbutton: {
        name: 'Smart lite button',
    },
    monthlysubscription: {
        name: 'Litemoment membership',
    }
};

interface State {
    fetching: boolean,
    orders: {[k:string]:any}[],
};

class OrderPage extends React.Component<Props, State> {
    _mounted: boolean;
    constructor(props:Props) {
        super(props);

        this.state = {
            fetching: false,
            orders: [],
        };
        this._mounted = false;
    }

    static getDerivedStateFromProps(props:Props, state:State) {
        return null;
    }

    async queryOrders() {
        try {
            this.setState({fetching: true});
            const r = await CloudAPI.getOrders();
            console.log(r);
            if (this._mounted) this.setState({orders: r.orders});
        } catch(e) {
            const msg = CloudAPI.getErrString(e);
            this.props.alert('Error querying paying orders', msg);
        } finally {
            if (this._mounted) this.setState({fetching: false});
        }
    }

    componentDidMount() { 
        this._mounted = true; 
        this.queryOrders();
    }

    componentWillUnmount() { this._mounted = false; }

    onGoPay(order:any) { window.location.href = order.sessionurl }

    onGoDetails(order:any) { dataUtils.goCustomerPortal(order._id) }

    renderOrders(orders:{[k:string]:any}[]) {
        if (orders.length === 0) return null;
        const now = new Date().getTime()/1000;
        const expireTS = now - 24*3600;
        return (<div><div>Recent orders:</div>
                <ul>
                {
                orders.map((order:{[k:string]:any}, i:number) => {
                    const item:keyof typeof ORDER_ITEMS = order.item;
                    const name = ORDER_ITEMS[item].name;
                    const created = AppUtils.unixToDatetime(order.createTS, '/', ' ', ':', 16);
                    const status = AppUtils.mapSubscribeStatus(order.state);
                    const paying = (order.state === Const.PAY_PAYING && order.createTS>expireTS);
                    const customid = (order.mode === 'subscription' && order.customid);
                    let nameSpan = (<span>{name}</span>);
                    if (paying) nameSpan = (<span className='clickable' onClick={() => this.onGoPay(order)}>{name}</span>);
                    if (customid) nameSpan = (<span className='clickable' onClick={() => this.onGoDetails(order)}>{name}</span>);
                    return <li key={i}><b>{created}</b> {nameSpan} ({status})</li>
                })
                }
                </ul>
            </div>)
    }
    
    render() {
        const {orders} = this.state;
        return (
            <div>
                <AppNav  active=""/>
                <div className="container">
                    <div className="flex-row title-row">
                        <div className="row-col-auto">
                            My orders
                            {this.state.fetching && (<img alt="Loading" src="/loading.gif" width={24} height={24}/>)}
                        </div>
                        <div></div>
                    </div>
                    <div className="row-seperator"/>
                    { this.renderOrders(orders) }
                    <div className="row-seperator"/>
                    <div><span className='clickable' onClick={() => window.location.href='/shopping/checkout'}>&gt; Shopping mall</span></div>
                </div>
            </div>
        );
    }
}

export default withUIHelpers(connector(OrderPage));
