import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Form, Button, } from 'react-bootstrap';
import {Container, Row, Col } from 'react-bootstrap';
import AppNav from '../AppNav';
import CloudAPI from '../CloudAPI';
//import history from '../history';
import {setAccount, Account} from './reducer';
import {RootState} from '../rootReducer';
import {withUIHelpers, PropsFromUIHelpers} from '../components/hocs';
import * as validators from '../Validators';


const mapState = (state:RootState) => ({account: state.account});
const mapDispatch = {setAccount, };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

interface State {
    password: string;
    passwordConfirm: string;
    passwordCurrent: string;
}

class ResetPasswordPage extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirm: '',
            passwordCurrent: '',
        };
    }

    componentDidMount() {
    }

    loginAccount(account:Account) {
        CloudAPI.setCredit(account.username, account.password);
        this.props.setAccount(account);
        //history.replace('/');
        this.props.navigate('/');
    }

    onChangeText(e:any, field:string)  {
        let value:string = (e.target as HTMLInputElement).value;
        if (field === 'passwordCurrent') this.setState({passwordCurrent:value});
        else if (field === 'password') this.setState({password:value});
        else if (field === 'passwordConfirm') this.setState({passwordConfirm:value});
    }

    onSubmitForm(e:any) {
        console.log(e);
        e.preventDefault();
        let {passwordCurrent, password, passwordConfirm, } = this.state;
        if (!validators.validatePassword(passwordCurrent)) {
            this.props.alert('Error', 'Invalid current password');
            return;
        }
        if (!validators.validatePassword(password)) {
            this.props.alert('Error', 'Invalid new password.');
            return;
        }
        if (password !== passwordConfirm) {
            this.props.alert('Error', 'New password and its confirmation not match.');
            return;
        }
        this.props.showBusy('Busy', 'Changing password');
        CloudAPI.changePassword(passwordCurrent, password, passwordConfirm).then((result:any) => {
            console.log(result);
            this.props.hideBusy();
            let account = {...this.props.account};
            account.password = password;
            CloudAPI.setCredit(account.username, account.password);
            this.props.setAccount(account);

            this.props.alert('Change password', 'Your password changed successfully.');

        }).catch(err => {
            this.props.hideBusy();
            let msg = CloudAPI.getErrString(err);
            this.props.alert('Cloud error', msg);
        });
    }

    render() {
        return (
            <div>
                <AppNav active="/resetpwd"/>
                <div>
                    <Container style={{maxWidth:600, textAlign:'left'}}>
                    <Row style={{padding:20, textAlign:'center'}}><Col>Change password</Col></Row>
                        <Form onSubmit={this.onSubmitForm.bind(this)}>
                            <Form.Group controlId="passwordCurrent">
                                <Form.Label>Current password</Form.Label>
                                <Form.Control type="password" placeholder="Type current password"
                                    value={this.state.passwordCurrent}
                                    onChange={(e:any) => {this.onChangeText(e, 'passwordCurrent');}}
                                    />
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Type new password"
                                    value={this.state.password}
                                    onChange={(e:any) => {this.onChangeText(e, 'password');}}
                                    />
                                <Form.Text className="text-muted">
                                At least 6 characters required.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="passwordConfirm">
                                <Form.Label>Confirm password</Form.Label>
                                <Form.Control type="password" placeholder="Type new password again"
                                    value={this.state.passwordConfirm}
                                    onChange={(e:any) => {this.onChangeText(e, 'passwordConfirm');}}
                                    />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Change
                            </Button>
                        </Form>

                        <div>&nbsp;</div>
                    </Container>
                </div>
            </div>
        );

    }
}

export default withUIHelpers(connector(ResetPasswordPage));
