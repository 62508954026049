import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,  } from '@fortawesome/free-solid-svg-icons';
import './TextButton.css';


const MyComponent = (
    props:{
        text:string, 
        icon?:typeof faCoffee, 
        onClick:()=>void,
    }) => {
    const icon = props.icon ? (<FontAwesomeIcon icon={props.icon} />):null;
    return (<span className="text-button" onClick={() => {props.onClick();}}>
        {icon}{ icon && (<span>&nbsp;</span>) }
        {props.text}
    </span>);
};

export default MyComponent;
