import store from './store';
import {DialogAlertProps} from './components/DialogAlert';
import {DialogBusyProps} from './components/DialogBusy';
import {DialogConfirmProps} from './components/DialogConfirm';
import {DialogSelectProps} from './components/DialogSelect';
import {DialogTextInputProps} from './components/DialogTextInput';
import {ToastProps, ToastListProps} from './components/ToastList';
import {
    setDialogAlert,
    setDialogBusy,
    setDialogConfirm,
    setDialogSelect,
    setDialogTextInput,
    setToastList,
    addToast,
    setPayment,
} from './reducer';
import {
    Tags,
    setTags,
} from './campaigns/reducer';
import CloudAPI from './CloudAPI';


// ---------------------------------------------------------------------------
// Modal dialogs

interface DialogCallbacks {
    alertClose?: () => void,
    busyClose?: () => void,
    confirmClose?: (action:boolean) => void,
    selectClose?: (action:boolean, value:string) => void,
    textInputClose?: (action:boolean, value:string) => void,
}

var _dialogCallbacks:DialogCallbacks = {};

export function showAlert(title:string, message:string, onClose?:()=>void) {
    const payload:DialogAlertProps = {
        visible: true,
        title,
        message,
    };
    _dialogCallbacks.alertClose = onClose;
    store.dispatch(setDialogAlert(payload));
}

export function closeAlert() {
    const payload:DialogAlertProps = {
        visible: false,
        title:'',
        message:'',
    };
    store.dispatch(setDialogAlert(payload));
    _dialogCallbacks.alertClose && _dialogCallbacks.alertClose();
}

export function showBusy(title:string, message:string, onClose?:()=>void) {
    const payload:DialogBusyProps = {
        visible: true,
        title,
        message,
    };
    _dialogCallbacks.busyClose = onClose;
    store.dispatch(setDialogBusy(payload));
}

export function closeBusy() {
    const payload:DialogBusyProps = {
        visible: false,
        title:'',
        message:'',
    };
    store.dispatch(setDialogBusy(payload));
    _dialogCallbacks.busyClose && _dialogCallbacks.busyClose();
}

export function showConfirm(title:string, message:string, onClose?:(action:boolean)=>void) {
    const payload:DialogConfirmProps = {
        visible: true,
        title,
        message,
    };
    _dialogCallbacks.confirmClose = onClose;
    store.dispatch(setDialogConfirm(payload));
}

export function closeConfirm(action:boolean) {
    const payload:DialogConfirmProps = {
        visible: false,
        title:'',
        message:'',
    };
    store.dispatch(setDialogConfirm(payload));
    _dialogCallbacks.confirmClose && _dialogCallbacks.confirmClose(action);
}

export function showSelect(title:string, value:string, items:{title:string, value:string}[], 
    onClose:(action:boolean, value:string)=>void) {
    const payload:DialogSelectProps = {
        visible: true,
        title,
        value,
        items,
    };
    _dialogCallbacks.selectClose = onClose;
    store.dispatch(setDialogSelect(payload));
}

export function closeSelect(action:boolean, value:string) {
    const payload:DialogSelectProps = {
        visible: false,
        title:'',
        value:'',
        items:[],
    };
    store.dispatch(setDialogSelect(payload));
    _dialogCallbacks.selectClose && _dialogCallbacks.selectClose(action, value);
}

export function showTextInput(title:string, value:string, onClose:(action:boolean, value:string)=>void) {
    const payload:DialogTextInputProps = {
        visible: true,
        title,
        value,
    };
    _dialogCallbacks.textInputClose = onClose;
    store.dispatch(setDialogTextInput(payload));
}

export function closeTextInput(action:boolean, value:string) {
    const payload:DialogTextInputProps = {
        visible: false,
        title:'',
        value:'',
    };
    store.dispatch(setDialogTextInput(payload));
    _dialogCallbacks.textInputClose && _dialogCallbacks.textInputClose(action, value);
}

// ---------------------------------------------------------------------------
// Toasts

var _toastId = 0;

export function showToast(title:string, message:string, delay?:number) {
    const xid = ++_toastId;
    const datetime = Date.now();
    const payload:ToastProps = {
        xid,
        datetime,
        title,
        message,
    };
    store.dispatch(addToast(payload));
}

export function closeToast(xid:number) {
    const toasts = store.getState().app.toastList.toasts;
    const toastsN = toasts.filter(t => t.xid !== xid);
    if (toasts.length !== toastsN.length) {
        const toastList:ToastListProps = {toasts:toastsN, };
        store.dispatch(setToastList(toastList));
    }
}

// ---------------------------------------------------------------------------
// Tags

export async function fetchPayment(modal=false, toast=false) {
    modal && showBusy('Cloud payment', 'Fetching payment...');
    try {
        const result:any = await CloudAPI.getSubscriptionState();
        modal && closeBusy();
        console.log(result);
        store.dispatch(setPayment(result));
        toast && showToast('Cloud payment', 'Cloud payment fetched successfully.');
        return true;
    } catch(err) {
        modal && closeBusy();
        const msg = CloudAPI.getErrString(err);
        showToast('Cloud payment', 'Error fetching payment: ' + msg);
        return false;
    }
}

export async function fetchTags(modal=false, toast=false) {
    modal && showBusy('Cloud Tag', 'Fetching cloud tags...');
    try {
        const result:any = await CloudAPI.getTags();
        modal && closeBusy();
        console.log(result);
        store.dispatch(setTags(result));
        toast && showToast('Cloud Tag', 'Cloud tags fetched successfully.');
        return true;
    } catch(err) {
        modal && closeBusy();
        const msg = CloudAPI.getErrString(err);
        showToast('Cloud Tag', 'Error fetching tags: ' + msg);
        return false;
    }
}

export async function saveTags(tags:Tags) {
    showBusy('Cloud Tag', 'Saving tags to cloud...');
    try {
        const result:any = await CloudAPI.setTags(tags);
        closeBusy();
        console.log(result);
        showToast('Cloud Tag', 'Cloud tags saved successfully.');
        return true;

    } catch(err) {
        closeBusy();
        const msg = CloudAPI.getErrString(err);
        showAlert('Cloud Tag', 'Error saving tags: ' + msg);
    }
}

export async function goCustomerPortal(order_id:string|null=null) {
    showBusy('Customer portal', 'Reidrecting to customer portal, wait please.');
    try {
        let r:any = await CloudAPI.customerPortal(order_id);
        window.location.href = r.url;

    } catch(e) {
        const msg = CloudAPI.getErrString(e);
        showAlert('Customer portal', 'Error: ' + msg);
    } finally {
        closeBusy();
    }
}
