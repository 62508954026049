import React from 'react'
import {Button, Modal} from 'react-bootstrap';


interface DialogBusyWaitProps {
    visiable:boolean,
    title?: string,
    message?: string,
}
export const DialogBusyWait = ({visiable, title='Busy', message='Please wait...'}:DialogBusyWaitProps) => {
    return (
        <Modal show={visiable} onHide={()=> {}}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
        </Modal>
    );
}

interface DialogAlertProps {
    visiable:boolean,
    title?: string,
    message?: string,
    onOK?:(()=>void)|null,
}
export const DialogAlert = ({visiable, title='Notice', onOK=null, message='Opps! Something bad happened.'}:DialogAlertProps) => {
    return (
        <Modal show={visiable} onHide={()=> {}}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {onOK && onOK(); }}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}
