import React from 'react';
import { Modal, Button } from "react-bootstrap";

export interface DialogConfirmProps {
    visible:boolean,
    title: string,
    message: string,
    onClose?:((action:boolean)=>void),
}

const Dialog = ({visible, title, message, onClose}:DialogConfirmProps) => {
    return (
      <Modal show={visible} onHide={() => { onClose && onClose(false); }}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { onClose && onClose(false); }}>
            Cancel
        </Button>
        <Button variant="primary" onClick={() => { onClose && onClose(true); }}>
            OK
        </Button>
      </Modal.Footer>
    </Modal>
    );
};

export default Dialog;
