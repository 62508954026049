import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

export interface DialogTextInputProps {
    visible:boolean,
    title: string,
    value: string,
    onClose?:((action:boolean, value:string)=>void),
}

const Dialog = ({visible, title, value, onClose}:DialogTextInputProps) => {
  const [current, setCurrent] = useState(value);
  useEffect(() => { 
    if (value !== current) setCurrent(value);
        // eslint-disable-next-line
  }, [value ]);

  return (
      <Modal show={visible} onHide={() => { onClose && onClose(false, current); }}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control 
            type="text" 
            placeholder={''} 
            value={current} 
            onChange={(ev:any) => {setCurrent(ev.target.value);}}
            />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { onClose && onClose(false, current); setCurrent(value); }}>
            Cancel
        </Button>
        <Button variant="primary" onClick={() => { onClose && onClose(true, current); setCurrent(value); }}>
            OK
        </Button>
      </Modal.Footer>
    </Modal>
    );
};

export default Dialog;
