import React from 'react'
import {Button, Modal} from 'react-bootstrap';

export interface DialogAlertProps {
    visible:boolean,
    title: string,
    message: string,
    onClose?:(()=>void),
}

const Dialog = ({visible, title='Attention please', onClose=undefined, message='Something unusual happened.'}:DialogAlertProps) => {
    return (
        <Modal show={visible} onHide={()=> {}}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {onClose && onClose(); }}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Dialog;
