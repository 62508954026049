import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Button, } from 'react-bootstrap';
// https://fontawesome.com/icons?d=gallery&p=3&s=brands,solid&m=free
import { faPlusCircle, faTimesCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../rootReducer';
import AppNav from '../AppNav';
import Const from '../Consts';
import '../styles.css';
import './styles.css';
import * as AppUtils from '../AppUtils';
import * as dataUtils from '../DataUtils';
import CloudAPI from '../CloudAPI';
import {PropsFromUIHelpers, withUIHelpers,} from '../components/hocs';


const mapState = (state:RootState) => ({});
const mapDispatch = { };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

const ORDER_ITEMS = {
    smartbutton: {
        name: 'Smart lite button',
    },
    monthlysubscription: {
        name: 'Litemoment membership',
    }
};

interface State {
    fetching: boolean,
    substate: {[k:string]:any},
    payings: {[k:string]:any}[],
    quantities: {[k:string]:number}
};

class CheckoutPage extends React.Component<Props, State> {
    _mounted: boolean;
    constructor(props:Props) {
        super(props);

        this.state = {
            fetching: false,
            substate: {},
            payings: [],
            quantities: {
                smartbutton: 1,
                monthlysubscription: 1
            }
        };
        this._mounted = false;
    }

    static getDerivedStateFromProps(props:Props, state:State) {
        return null;
    }

    async querySubscriptionState() {
        try {
            this.setState({fetching: true});
            const r = await CloudAPI.getSubscriptionState();
            console.log(r);
            if (this._mounted) this.setState({substate: r});
        } catch(e) {
            const msg = CloudAPI.getErrString(e);
            this.props.alert('Error querying subscription', msg);
        } finally {
            if (this._mounted) this.setState({fetching: false});  
        }
    }

    async queryPayingOrders() {
        try {
            this.setState({fetching: true});
            const r = await CloudAPI.getPayingOrders();
            console.log(r);
            if (this._mounted) this.setState({payings: r.orders});
        } catch(e) {
            const msg = CloudAPI.getErrString(e);
            this.props.alert('Error querying paying orders', msg);
        } finally {
            if (this._mounted) this.setState({fetching: false});  
        }
    }

    componentDidMount() { 
        this._mounted = true; 
        this.querySubscriptionState(); 
        this.queryPayingOrders();
    }

    componentWillUnmount() { this._mounted = false; }

    async onCheckout(item:string) {
        const quantity = this.state.quantities[item];
        this.props.showBusy('Checkout', 'Creating order from cloud, wait please.');
        try {
            const r:any = await CloudAPI.checkout(item, quantity);
            if (r && r.url) {
                window.location.href = r.url
            } else {
                console.error(r);
                throw new Error('Unexpected response');
            }
        } catch(e) {
            const msg = CloudAPI.getErrString(e);
            this.props.alert('Error shopping', msg);
        } finally {
            this.props.hideBusy();
        }
    }

    async onResumeOrder(order:any) {
        window.location.href = order.sessionurl;
    }

    async onViewOrders() {
        this.props.navigate('/shopping/orders');
    }

    onRemoveSubscription() {
        dataUtils.showConfirm('Remove', 'Do you really want to remove subscription?', async (action) => {
            if (action) {
                try {
                    this.props.showBusy('Remove', 'Removing subscription, wait please.');
                    await CloudAPI.removeSubscription();
                    //this.props.navigate(0);
                    //window.location.reload();
                    await this.querySubscriptionState()

                } catch(e) {
                    const msg = CloudAPI.getErrString(e);
                    this.props.alert('Error removing subscription', msg);
                } finally {
                    this.props.hideBusy();
                }
            }
        })
    }

    renderSubscription(substate:{[k:string]:any}) {
        const valid = (Object.keys(substate).length > 0);
        const active = substate.active;
        const canceled = (substate.state === Const.PAY_CANCELED);
        const dued = (substate.state === Const.PAY_PAST_DUE);
        const cancel = substate.cancelTS ?  AppUtils.unixToDatetime(substate.cancelTS, '/', ' ', ':', 10) : '';
        const start = valid ? AppUtils.unixToDatetime(substate.startTS, '/', ' ', ':', 10) : '';
        const end = valid ? AppUtils.unixToDatetime(substate.endTS, '/', ' ', ':', 10) : '';
        const status = (valid ? AppUtils.mapSubscribeStatus(substate.state) : '');
        if (!valid) return null;
        return (<div className='messagebox'>
                <div>Current subscription is <b>{status}</b>{(active || dued) && (<span>, valid through <b>{start} - {end}</b></span>)}.</div>
                {cancel && (<div>{!canceled ? 'Current subscription will be canceled on':'Current subscription was canceled on'} <b>{cancel}</b>.</div>)}
                <div>
                    (<span className='clickable' onClick={() => dataUtils.goCustomerPortal()}>&nbsp;Review&nbsp;</span> 
                    { false && (<span>|</span>)}
                    { false && (<span className='clickable' onClick={() => this.onRemoveSubscription()}>&nbsp;Remove&nbsp;</span>) })
                </div>
            </div>)
    }

    renderPayingOrders(orders:{[k:string]:any}[]) {
        if (orders.length === 0) return null;
        return (<div><div>Orders to pay:</div>
                <ul>
                {
                orders.map((order:{[k:string]:any}, i:number) => {
                    const item:keyof typeof ORDER_ITEMS = order.item;
                    const name = ORDER_ITEMS[item].name;
                    const created = AppUtils.unixToDatetime(order.createTS, '/', ' ', ':', 16);
                    return <li key={i} className='clickable' onClick={() => this.onResumeOrder(order)}>{created} {name}</li>
                })
                }
                </ul>
            </div>)
    }

    onUpdateQuantities(item:string, quantity:number) {
        let quantities = {...this.state.quantities};
        quantities[item] = quantity;
        this.setState({quantities});
    }
    
    render() {
        const {substate, payings} = this.state;
        return (
            <div>
                <AppNav active="/shopping/checkout" />
                <div className="container">
                <div className="flex-row title-row">
                        <div className="row-col-auto">
                            Shopping mall
                            {this.state.fetching && (<img alt="Loading" src="/loading.gif" width={24} height={24}/>)}
                        </div>
                        <div>
                            <Button variant="primary" size="lg" className="action-button" onClick={() => this.onViewOrders()}>My orders</Button></div>
                    </div>
                    <div className="row-seperator"/>
                    { this.renderSubscription(substate) }
                    { this.renderPayingOrders(payings) }
                    <div className="row-seperator"/>
                    <div className="flex-row">
                    <section>
                        <div className="product">
                            <img src="/litebutton.png" alt="Smart Lite Button" />
                            <div className="description">
                            <h3>{ORDER_ITEMS.smartbutton.name}</h3>
                            <h5>$30.00</h5>
                            <input type="number" className="quantity" min="1" max="99" 
                                value={this.state.quantities.smartbutton} 
                                onChange={(e) => this.onUpdateQuantities('smartbutton', parseInt(e.target.value))} />
                            </div>
                        </div>
                        <button type="button" disabled={payings.findIndex(v => v.item=='smartbutton')>=0} 
                            id="checkout-button-smartbutton" 
                            onClick={() => this.onCheckout('smartbutton')}>Checkout</button>
                    </section>
                    </div>
                    <div className="flex-row">
                    <section>
                        <div className="product">
                            <img src="/litemomentmember.png" alt="litemoment membership" />
                            <div className="description">
                            <h3>{ORDER_ITEMS.monthlysubscription.name}</h3>
                            <h5>$9.99 per month</h5>
                            <input type="number" className="quantity" disabled min="1" max="1" 
                                value={this.state.quantities.monthlysubscription} 
                                onChange={(e) => this.onUpdateQuantities('monthlysubscription', parseInt(e.target.value))} />
                            </div>
                        </div>
                        <button type="button" disabled={substate.active || payings.findIndex(v => v.item=='monthlysubscription')>=0} 
                            id="checkout-button-monthlysubscription" 
                            onClick={() => this.onCheckout('monthlysubscription')}>Checkout</button>
                    </section>
                    </div>
                    <div className="row-seperator"/>
                </div>
            </div>
        );
    }
}

export default withUIHelpers(connector(CheckoutPage));
