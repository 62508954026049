const Consts = {
    REST_URI: 'http://rest.litemoment.com',
    REST_URI_S: 'https://rest.litemoment.com',
    WS_URI: 'ws://rest.litemoment.com/synccamps',  
    WSS_URI: 'wss://rest.litemoment.com/synccamps',
    //REST_URI: 'http://127.0.0.1:8080',
    //REST_URI_S: 'https://127.0.0.1:8080',
    //WS_URI: 'ws://127.0.0.1:8080/synccamps',
    //WSS_URI: 'wss://127.0.0.1:8080/synccamps',
    STORAGE_ACCOUNT: 'account',
    MAX_FREE_TAGS: 10,
    MAX_FREE_EVENTS: 100,
    PAY_CREATED: 0, // 订单已创建，未支付
    PAY_PAYING: 1, // 订单支付中
    PAY_DELAYED: 2, // 订单延时支付
    PAY_PAID: 3, // 订单单次购买完成，或订阅首次付款成功，订单会话终结
    PAY_FAILED: 4, // 订单延迟支付失败，订单会话终结
    PAY_EXPIRED: 5, // 订单支付超时失败，订单会话终结
    PAY_SHIPPED: 6, // 已发货
    PAY_COMPLETED: 7, // 已收货
    PAY_ACTIVE: 8, // 订阅正常，status=active
    PAY_PAST_DUE: 9, // 订阅续费失败，重试中
    // 订阅已取消服务到期后，或者订阅未取消但支付未成功过了deadline后，进入canceled状态
    PAY_CANCELED : 10, // 订阅进入终止状态
}

export default Consts;
