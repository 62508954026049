import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";

export interface DialogSelectProps {
    visible:boolean,
    title: string,
    value: string,
    items: {title:string, value:string}[],
    onClose?: (action:boolean, value:string)=>void,
}

const Dialog = ({visible, title, value, items, onClose}:DialogSelectProps) => {
  const [current, setCurrent] = useState(value);
  useEffect(() => {
    if (value !== current) setCurrent(value);
    // eslint-disable-next-line
  }, [value, ]);

  return (
      <Modal show={visible} onHide={() => { onClose && onClose(false, current); }}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{
        items.map(v =>
          (<label key={`modalDlgSelectSingle${v.value}`} 
            onClick={()=>{setCurrent(v.value);}} 
            style={{paddingLeft:5}}>
            <input type="radio" value={v.value} checked={v.value===current}/>{v.title}
          </label>)
        )
      }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { onClose && onClose(false, current); }}>
            Cancel
        </Button>
        <Button variant="primary" onClick={() => { onClose && onClose(true, current); }}>
            OK
        </Button>
      </Modal.Footer>
    </Modal>
    );
};

export default Dialog;
