import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Button,  } from 'react-bootstrap';
import {Navbar, Nav, NavDropdown, } from 'react-bootstrap';
// https://fontawesome.com/icons?d=gallery&p=3&s=brands,solid&m=free
import { faPlusCircle, faTimesCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../rootReducer';
import AppNav from '../AppNav';
import Const from '../Consts';
import TextButton from '../components/TextButton';
import './styles.css';
import * as AppUtils from '../AppUtils';
import * as dataUtils from '../DataUtils';


const mapState = (state:RootState) => ({
    tags: state.campaigns.tags,
    payment: state.app.payment,
});
const mapDispatch = { };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface State {};

class CancelPage extends React.Component<Props, State> {
    _mounted: boolean;
    constructor(props:Props) {
        super(props);

        this.state = {
            fetching: false,
            tags: props.tags,
        };
        this._mounted = false;
    }

    static getDerivedStateFromProps(props:Props, state:State) {
        return null;
    }

    componentDidMount() { this._mounted = true; }

    componentWillUnmount() { this._mounted = false; }

    render() {
        return (
            <div>
                <AppNav  active=""/>
                <div className="container">
                    <div className='messagebox'>
                        <div>Forgot to add something to your cart? Shop around then come back to pay!</div>
                        <div><span className='clickable' onClick={() => window.location.href='/shopping/checkout'}>&gt; Shopping mall</span></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connector(CancelPage);
