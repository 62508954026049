import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Button, ListGroup } from 'react-bootstrap';
import {RootState} from '../rootReducer';
import {MatchEvent, } from './reducer';
import AppNav from '../AppNav';
import CloudAPI from '../CloudAPI';
import * as AppUtils from '../AppUtils';
import {withUIHelpers, PropsFromUIHelpers} from '../components/hocs';
import '../styles.css';


const mapState = (state:RootState) => ({});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, PropsFromUIHelpers {}

interface State {
    events: MatchEvent[],
    fetching: boolean,
}

function getEventDetail(ev:MatchEvent) {
    const descs:{[k:string]:string} = {
        HADD: 'Home team scored',
        HSUB: 'Home team lost',
        GADD: 'Guest team scored',
        GSUB: 'Guest team lost',
        RESET: 'Score reset',
        LITE: 'Lite moment',
        ANOTE: 'Audio message',
        TNOTE: 'Text message',
        TAG: 'Tag event',
        VAR: 'Var event',
    };
    let desc = descs[ev.eventType];
    if (ev.eventType === 'TNOTE' || ev.eventType === 'TAG') {
        desc += ` - ${ev.eventData}`;
    }
    return desc;
}

class ViewPage extends React.Component<PropsFromRedux & PropsFromUIHelpers, State> {
    _mounted: boolean;

    constructor(props:Props) {
        super(props);
        this.state = {
            events: [],
            fetching: false,
        };
        this._mounted = false;
    }

    async loadEventsFromCloud() {
        this.setState({fetching: true});
        CloudAPI.getEvents().then((result:any) => {
            if (!this._mounted) return;
            this.setState({fetching: false});
            console.log(result);
            let events:MatchEvent[] = [];
            for (let item of result['_items']) {
                let ev = item as MatchEvent;
                events.push(ev);
            }
            this.setState({events, });

        }).catch(err => {
            if (!this._mounted) return;
            this.setState({fetching: false});
            let msg = CloudAPI.getErrString(err);
            this.props.alert('Cloud Error', msg);
        });
    }

    componentDidMount() {
        this._mounted = true;
        this.loadEventsFromCloud();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    renderEvents() {
        if (this.state.fetching) {
            return (
                <ListGroup></ListGroup>
            );
        }
        return (
            <ListGroup style={{width:'100%'}}>
                {
                    this.state.events.map((v,idx) => {
                        let evTime = AppUtils.unixToDatetime(v.eventTS);
                        let evData = v.eventData;
                        if (!evData) evData = '';
                        return (
                            <ListGroup.Item key={`ViewEvent_${idx}`} 
                                style={{textAlign:'left'}}
                                >{evTime} { getEventDetail(v) }
                            </ListGroup.Item>
                        );
                    })
                }
            </ListGroup>            
        )
    }

    render() {
        return (
            <div>
                <AppNav active="/view"/>
                <div className="container">
                    <div className="flex-row title-row">
                        <div className="row-col-auto">
                            View Events
                            {this.state.fetching && (<img alt="Loading" src="loading.gif" width={24} height={24}/>)}
                        </div>
                        <div>
                            <Button size="lg" className="action-button" onClick={() => {this.loadEventsFromCloud();}}>Refresh</Button>
                        </div>
                    </div>
                    <div className="row-seperator"/>
                    <div className="flex-row">
                            {this.renderEvents()}
                    </div>
                </div>
            </div>
        );
    }
}

const DecoratedView = withUIHelpers(connector(ViewPage));
export default DecoratedView;
