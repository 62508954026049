//import {Buffer} from 'buffer';
import moment from 'moment';
import C from './Consts';

export type Param2_1<T> = T extends (p1:infer  U, p2:any)=>any ? U:never;
export type Param2_2<T> = T extends (p1:any, p2:infer U)=>any ? U:never;
export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export type Style = {[key:string]:any};

export function PropAssign<T>(obj:T, prop:keyof T, value:any):void { obj[prop] = value; }

export function sortObjectsByProp<T>(objs:T[], prop:keyof T, ascend=true):T[] {
  if (ascend) {
    objs.sort((a,b) => {
      if (a[prop]>b[prop]) return 1;
      else if (a[prop]<b[prop]) return -1;
      return 0;
    });
  } else {
    objs.sort((a,b) => {
      if (a[prop]>b[prop]) return -1;
      else if (a[prop]<b[prop]) return 1;
      return 0;
    });
  }
  return objs;
}

export function arrayDeepCopy(srcArr:any):any[] {
  let dstArr = [];
  for (let i=0; i<srcArr.length; i++) {
      let value = srcArr[i];
      if (value instanceof Array) {
          dstArr.push(arrayDeepCopy(value));
      } else if (value instanceof Object) {
          dstArr.push(objectDeepCopy(value));
      } else {
          dstArr.push(value);
      }
  }
  return dstArr;
}

export function objectDeepCopy(srcObj:{[key:string]:any}) { 
  let dstObj:{[key:string]:any} = {};
  for (let key in srcObj) {
      if (srcObj.hasOwnProperty(key)) {
          let value = srcObj[key];
          if (value instanceof Array) {
              dstObj[key] = arrayDeepCopy(value);
          } else if (value instanceof Object) {
              dstObj[key] = objectDeepCopy(value);
          } else {
              dstObj[key] = value;
          }
      }
  }
  return dstObj;
}

export function variableType(v:any):string|null {
  // Array|Object|String|Number|Boolean|Null|Undefined|RegExp|...
  let vT = Object.prototype.toString.call(v);
  let result = vT.replace(/[[\]]/g, '').split(' ').pop();
  if (result) return result;
  return null;
}

export function variableDeepCopy(v:any) {
  let vT =  variableType(v);
  if (vT === 'Array') return arrayDeepCopy(v);
  else if (vT === 'Object') return objectDeepCopy(v);
  else return v;
}

export function isVariableSame(a:any, b:any) {
  let aT =  variableType(a);
  let bT = variableType(b);
  if (aT !== bT) return false;
  if (aT === 'Array') return isArraySame(a, b);
  else if (aT === 'Object') return isObjectSame(a, b);
  else return a === b;
}

export function isArraySame(a:any, b:any) {
  let aT = variableType(a);
  let bT = variableType(b);
  if (aT !== 'Array' || bT !== 'Array') return false;
  if (a.length !== b.length) return false;
  for (let i=0; i<a.length; i++) {
    if (!isVariableSame(a[i],  b[i])) return false;
  }
  return true;
}

export function uniqueObjectProps(objs:any, keyName:string) {
    let result = [];
    if (variableType(objs) === 'Array') {
        for (let obj of objs) result.push(obj[keyName]);
    } else {
        let keys = Object.keys(objs);
        for (let key of keys)  result.push(objs[key][keyName]);
    }
    return uniqueValues(result);
}

export function uniqueValues(values:any[]) {
    let uniques = values.filter((value, index, self) => {return self.indexOf(value) === index;});
    return uniques;
}

export function uniqueObjects(objs:any, keyName:string, overwrite:(a:any, b:any)=>boolean) {
    if (variableType(objs) === 'Array') {
        let result:any = [];
        for (let i=0; i<objs.length; i++) {
            let obj = objs[i];
            // eslint-disable-next-line
            let idx = result.findIndex((v:any) => v[keyName]==obj[keyName]);
            if (idx >= 0) {
                let lastOne = overwrite(result[idx], obj);
                result[idx] = lastOne ? obj:result[idx];
            }
            else result.push(obj);
        }
        return result;
    }

    let keys = Object.keys(objs);
    let resultKeys:any = [];
    for (let i=0; i<keys.length; i++) {
        let currentKey = keys[i];
        // eslint-disable-next-line
        let idx = resultKeys.findIndex((key:any) => objs[key][keyName]==objs[currentKey][keyName]);
        if (idx >= 0) {
            let resultKey = resultKeys[idx];
            let lastOne = overwrite(objs[resultKey], objs[currentKey]);
            resultKeys[idx] = lastOne ? currentKey:resultKey;
        }
        else resultKeys.push(currentKey);
    }
    let result:any = {}
    for (let key of resultKeys) result[key] = objs[key];
    return result;
}

export function filterObjects(objs:any, filter:(v:any)=>boolean) {
    if (variableType(objs) === 'Array') {
        return objs.filter((v:any) => filter(v));
    }
    let keys = Object.keys(objs);
    let result:any = {};
    for (let key of keys) {
        if (filter(objs[key])) result[key] = objs[key];
    }
    return result;
}

export function filterObjectsOne(objs:any, filter:(v:any)=>boolean) {
    if (variableType(objs) === 'Array') {
        for (let obj of objs) {
            if (filter(obj)) return obj;
        }
        return null;
    }
    let keys = Object.keys(objs);
    for (let key of keys) {
        if (filter(objs[key])) return objs[key];
    }
    return null;
}

export function filterObjectByProps(objs:any, keyName:string, filter:(v:any)=>boolean) {
    if (variableType(objs) === 'Array') {
        return objs.filter((v:any) => filter(v[keyName]));
    }
    let keys = Object.keys(objs);
    let result:any = {};
    for (let key of keys) {
        if (filter(objs[key][keyName])) result[key] = objs[key];
    }
    return result;
}

export function diffObjectsHash(base:any, objs:any, filter:(a:any, b:any) => boolean) {
    let keys = Object.keys(objs);
    let result:{[k:string]:any} = {};
    for (let key of keys) {
        if (typeof(base[key]) === 'undefined') result[key] = objs[key];
        else if (filter(base[key], objs[key])) result[key] = objs[key];
    }
    return result;
}

export function diffObjectKeys(base:any, objs:any, filter:(a:any, b:any)=>boolean) {
    let keys = Object.keys(objs);
    let result = [];
    for (let key of keys) {
        if (typeof(base[key]) === 'undefined') result.push(key);
        else if (filter(base[key], objs[key])) result.push(key);
    }
    return result;
}

export function mapObjects(objs:any, map:(key:any, obj:any) => [any, any]) {
    let keys = Object.keys(objs);
    let result:{[k:string]:any} = {};
    for (let key of keys) {
        let [keyNew, objNew] = map(key, objs[key]);
        result[keyNew] = objNew;
    }
    return result;
}

export function mergeObjects(base:any, other:any, keyName:string, overwrite:(a:any,b:any)=>boolean) {
    if (variableType(base) === 'Array') {
        if (variableType(other)!=='Array') other = hashMapToArray(other);
        return uniqueObjects(base.concat(other), keyName, overwrite);   
    }

    if (variableType(other)==='Array') throw new Error('Merge incompatible objects');
    let cands = Object.assign(base, other);
    return uniqueObjects(cands, keyName, overwrite);
}

export function groupObjectByProps(objs:any, keyName:string, overwrite:(a:any,b:any)=>boolean) {
    let result:any = {};
    let testArray = objs;
    if (variableType(objs) !== 'Array') testArray = hashMapToArray(objs);
    for (let obj of testArray) {
        let keyValue = obj[keyName];
        if (typeof(result[keyValue]) === 'undefined') {
            result[keyValue] = obj;
        } else {
            let lastOne = overwrite(result[keyValue], obj);
            result[keyValue] = lastOne ? obj:result[keyValue];
        }
    }
    return result;
}

export function arrayToHashMap(arr:any, keyName:string) {
    if (variableType(arr) !== 'Array') return arr;
    let result:{[k:string]:any} = {};
    for (let item of arr) {
        result[item[keyName]] = item;
    }
    return result;
}

export function hashMapToArray(objs:any):any[] {
    if (variableType(objs) === 'Array') return objs;
    let result = [];
    let keys = Object.keys(objs);
    for (let key of keys) {
        result.push(objs[key]);
    }
    return result;
}

export function getRangeOfObjectProps(objs:any, keyName:string) {
    let objArray = hashMapToArray(objs);
    let [min, max] = [null, null];
    for (let obj of objArray) {
        if (min==null || obj[keyName]<min!) min = obj[keyName];
        if (max==null || obj[keyName]>max!) max = obj[keyName];
    }
    return [min, max];
}

export function fixObjectProps(objs:any, keyName:string, fixKeyName:string|null, fixValue:any=null) {
    let objArray = hashMapToArray(objs);
    for (let obj of objArray) {
        if (fixKeyName != null) obj[keyName] = obj[fixKeyName];
        else obj[keyName] = fixValue;
    }
    return objs;
}

export function getNumberOfObjects(objs:any) {
    if (variableType(objs)==='Array') return objs.length;
    return Object.keys(objs).length;
}

export function isObjectSame(a:any, b:any) {
  let aT = variableType(a);
  let bT = variableType(b);
  if (aT !== 'Object' || bT !== 'Object') return false;
  let aKeys = Object.keys(a);
  let bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  for (let i=0; i<aKeys.length; i++) {
    let key = aKeys[i];
    if (typeof b[key] === "undefined") return false;
    if (!isVariableSame(a[key], b[key])) return false;
  }
  return true;
}

export function dateToString(dt:Date, dtsep:string='-', sep:string=' ', tmsep:string=':'):string {
  let year = dt.getFullYear();
  let month = ''+(dt.getMonth()+1);
  let day = ''+dt.getDate();
  if (month.length<2) month = '0'+month;
  if (day.length<2) day = '0'+day;

  let hour = ''+dt.getHours();
  let minute = ''+dt.getMinutes();
  let second = ''+dt.getSeconds();
  if (hour.length<2) hour = '0'+hour;
  if (minute.length<2) minute = '0'+hour;
  if (second.length<2) second = '0'+second;

  return `${year}${dtsep}${month}${dtsep}${day}${sep}${hour}${tmsep}${minute}${tmsep}${second}`;
}

export function dateToDateString(dt:Date, dtsep:string='-'):string {
  let result = dateToString(dt, dtsep); // 1977-10-03 12:10:30
  return result.substring(0, 10);
}

export function dateToTimeString(dt:Date, tmsep:string=':'):string {
  let result = dateToString(dt, '-', ' ', tmsep); // 1977-10-03 12:10:30
  return result.substring(11);
}

export function unixToDatetime(ts: number, dtsep:string='-', sep:string=' ', tmsep:string=':', len:number|null=null): string {
  let r = moment.unix(ts).format(`YYYY${dtsep}MM${dtsep}DD${sep}HH${tmsep}mm${tmsep}ss`);
  if (len) return r.substring(0, len);
  else return r;
}

export function datetimeToUnix(ds: string, dtsep:string='-', sep:string=' ', tmsep:string=':'): number {
  return moment(ds, `YYYY${dtsep}MM${dtsep}DD${sep}HH${tmsep}mm${tmsep}ss`).unix();
}

export function unixToDate(ts:number):Date {
  return new Date(ts*1000);
}

export function dateToUnix(dt:Date):number {
  return Math.floor(dt.getTime()/1000);
}

export function base64ToStr(base64: string, encode = 'utf8'): string {
  //return Buffer.from(base64, 'base64').toString(encode);
  // Decode the Base64 string back to the original string, handling Unicode characters
  const s = decodeURIComponent(Array.prototype.map.call(atob(base64), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  return s;
}

export function strToBase64(str: string, encode:'utf-8'|'utf8' = 'utf8'): string {
  //return Buffer.from(str, encode).toString('base64');
  // Encode the string to Base64, handling Unicode characters
  let s = btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
  return s;
}

export function getFileDirname(filePath:string) {
  let idx = filePath.lastIndexOf('/');
  if (idx < 0) return './';
  return filePath.substr(0, idx);
}

export function getFileBasename(filePath:string) {
  let idx = filePath.lastIndexOf('/');
  if (idx < 0) return filePath;
  return filePath.substr(idx+1);
}

export function getFileExt(filePath:string, withDot=false) {
  let fileName = getFileBasename(filePath);
  let idx = fileName.lastIndexOf('.');
  if (idx < 0) return '';
  if (withDot) return fileName.substr(idx);
  else return fileName.substr(idx+1);
}

export function getFileFirstname(filePath:string) {
  let fileName = getFileBasename(filePath);
  let idx = fileName.lastIndexOf('.');
  if (idx<0) return fileName;
  return fileName.substr(0, idx);
}

export const Letter2Meta:{[key:string]:string} = {
  'T': 'time',
  'S': 'size',
  'D': 'duration',
  'F': 'fps',
  'R': 'bitrate',
  'O': 'oriention',
};

export function getFileMeta(filePath:string) {
  let fileFirstname = getFileFirstname(filePath);
  let fileExt = getFileExt(filePath);
  let elems = fileFirstname.split('_');
  let meta:{[key:string]:any} = {ext:fileExt,};
  for (let i=0; i<elems.length; i++) {
    if (elems[i].startsWith('M@')) {
      let metaLine = elems[i].substr(2);
      let metaItems = metaLine.split('-');
      for (let j=0;  j<metaItems.length; j++) {
        if (!metaItems[j] || metaItems[j].length===0) continue;
        let item = metaItems[j];
        let letter = item.substr(0, 1);
        if (!Letter2Meta[letter]) continue;
        let key = Letter2Meta[letter];
        let value = item.substr(1);
        meta[key] = value;
        if (letter==='S') {
          let [width,height] = value.split('x');
          meta['width'] = parseInt(width);
          meta['height'] = parseInt(height);
        }
      }
    }
  }
  return meta;
}

export function getFileMime(filePath:string):string {
  const ImageTypes:{[key:string]:string} = {
    'gif':'gif', 'jpg':'jpeg', 'jpeg':'jpeg', 'png':'png', 
    'webp':'webp', 'svg':'svg+xml', 'icon':'x-icon',
  };
  const AudioTypes:{[key:string]:string} = {
    'wav':'wav', '3gp':'ogg', 'webm':'webm', 'mp3':'mpeg',
    'au':'basic', 'snd':'basic', 'mid':'mid',
  };
  const VideoTypes:{[key:string]:string} = {
    'mp4':'mp4', '3gp':'3gpp', 'avi':'x-msvideo', 'wmv':'x-ms-wmv',
    'ts':'MP2T', 'mov':'quicktime', 'flv':'x-flv',
  };
  let fileExt = getFileExt(filePath);
  if (fileExt.length>0) {
    if (ImageTypes[fileExt]) return 'image/'+ImageTypes[fileExt];
    if (AudioTypes[fileExt]) return 'audio/'+AudioTypes[fileExt];
    if (VideoTypes[fileExt]) return 'video/'+VideoTypes[fileExt];
  }
  return 'application/octet-stream';
}

export function  mapSubscribeStatus(state:number) {
  switch (state) {
      case C.PAY_CREATED: return 'created';
      case C.PAY_PAYING: return 'paying';
      case C.PAY_DELAYED: return 'delayed';
      case C.PAY_FAILED: return 'failed';
      case C.PAY_EXPIRED: return 'expired';
      case C.PAY_PAID: return 'paid';
      case C.PAY_SHIPPED: return 'shipped';
      case C.PAY_COMPLETED: return 'completed';
      case C.PAY_ACTIVE: return 'active';
      case C.PAY_PAST_DUE: return 'dued';
      case C.PAY_CANCELED: return 'removed';
      default: throw new Error('Unknown subscription state');
  }
}
