import React from "react";
import { useNavigate, useLocation, NavigateFunction, Location } from 'react-router-dom';
import {DialogBusyWait, DialogAlert } from '../components/dialogs';

export interface PropsFromUIHelpers {
    alert(title:string, message:string, onOK?:()=>void): void,
    showBusy(title:string, message?:string):void,
    hideBusy():void,
    navigate:NavigateFunction,
    location:Location,
}

interface withUIHelpersState {
    showDlgBusy: boolean;
    dlgBusyTitle: string;
    dlgBusyMessage: string;
    showDlgAlert: boolean;
    dlgAlertTitle: string;
    dlgAlertMessage: string;
    onAlertOK: (()=>void)|null;
}

// Define a type for props that the wrapper accepts, including a dynamic component and its props
interface WrapperProps {
    Component: React.ComponentType<any>; // Accepts any component type
    [key: string]: any; // For additional props
};

const ClassComponentWrapper: React.FC<WrapperProps> = ({ Component, ...props }) => {
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation()
  
    // Pass `navigate` and any other props down to the Component
    return <Component navigate={navigate} location={location} {...props} />;
};

export function withUIHelpers<P extends PropsFromUIHelpers>(MyComponent: React.ComponentType<P>) {
    type ExternalProps = Omit<P, keyof PropsFromUIHelpers>;

    return class extends React.Component<ExternalProps, withUIHelpersState> {
        // static properties of wrapped component cannot be detected by withNaviation
        // static someStaticOptions = Component.someStaticOptions;

        constructor(props: P) {
            super(props);

            this.state = {
                showDlgBusy: false,
                dlgBusyTitle: 'Busy',
                dlgBusyMessage: 'Please wait...',
                showDlgAlert: false,
                dlgAlertTitle: 'Notice',
                dlgAlertMessage: '',
                onAlertOK: null
            };

            this.alert = this.alert.bind(this);
            this.showBusy = this.showBusy.bind(this);
            this.hideBusy = this.hideBusy.bind(this);
        }

        alert(title: string, message: string, onOK: (() => void) | null = null) {
            this.setState({
                showDlgAlert: true,
                dlgAlertTitle: title,
                dlgAlertMessage: message,
                onAlertOK: onOK
            });
        }

        showBusy(title: string, message: string = 'Please wait...') {
            this.setState({
                showDlgBusy: true,
                dlgBusyTitle: title,
                dlgBusyMessage: message
            });
        }

        hideBusy() {
            this.setState({ showDlgBusy: false });
        }

        componentDidMount() { }

        componentWillUnmount() { }

        render() {
            const injectedProps = {
                alert:this.alert,
                showBusy:this.showBusy,
                hideBusy:this.hideBusy,
            };

            return (
                <>
                    <DialogBusyWait
                        visiable={this.state.showDlgBusy}
                        title={this.state.dlgBusyTitle}
                        message={this.state.dlgBusyMessage}
                    />
                    <DialogAlert
                        visiable={this.state.showDlgAlert}
                        title={this.state.dlgAlertTitle}
                        message={this.state.dlgAlertMessage}
                        onOK={() => {
                            this.setState({ showDlgAlert: false });
                            this.state.onAlertOK && this.state.onAlertOK();
                        }}
                    />
                    <ClassComponentWrapper Component={MyComponent} {...this.props as P}  {...injectedProps } />
                </>
            );
        }
    };
}
