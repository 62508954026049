import { redirect } from 'react-router-dom';
import Consts from './Consts';

type EncodeType = "utf8" | "ascii"|"base64"|"utf16le"|"latin1"|"binary";
//type ResolveType = (value?: {} | PromiseLike<{}> | undefined) => void;
type ResolveType = (value: {[key: string]: any;} | PromiseLike<{[key: string]: any;}>) => void;
type RejectType = (reason?: any) => void;
type FormValuesType = {[key:string]:any};

 class CloudAPIComponent {
    username: string;
    password: string;
    base: string;

  constructor(apiBase:string|null=null) {
    if (!apiBase) {
      apiBase = Consts.REST_URI;
      if (window.location && window.location.href) {
        let href = window.location.href;
        if (href.toLocaleLowerCase().startsWith('https://')) {
          apiBase = Consts.REST_URI_S;
        }
      }
    }
    this.username = '';
    this.password = '';
    this.base = apiBase;
  }

  setCredit(username:string, password:string) {
    this.username = username;
    this.password = password;
  }

  isCreditSet() {
    return this.username.length>0 && this.password.length>0;
  }

  base64Encode(str:string, encode:EncodeType='utf8') {
    let s = btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
    return s;
    //return Buffer.from(str, encode).toString('base64');
  }

  base64Decode(bs:string,  encode:EncodeType='utf8') {
    const s = decodeURIComponent(Array.prototype.map.call(atob(bs), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return s;
  
    //return Buffer.from(bs, 'base64').toString(encode);
  }

  encodeBasicAuth() {
    let credit = this.username + ':' + this.password;
    let encoded = 'Basic ' + this.base64Encode(credit);
    return encoded;
  }

  addBasicAuthHeader(headers:Headers) {
      headers.append('Authorization', this.encodeBasicAuth());
      //headers['Authorization'] = this.encodeBasicAuth();
      return headers;
  }

  getRequestURL(uriPath:string) {
    if (uriPath.startsWith("/")) return this.base+uriPath;
    else return this.base  + '/' + uriPath;
  }

  getErrString(err:any) {
    if (!err) return 'Error';
    if (err.message) return err.message;
    if (err._error) {
        let {code, message} = err._error;
        return `HTTP Error ${code}: ${message}`;
    }
    return ''+ err;
  }

  dumpHeaders(headers:Headers) {
    let keys:string[] = [];
    let values = [];
    let iter = headers.keys();
    while (true) {
      let i = iter.next();
      if (!i || i.done) break;
      keys.push(i.value);
      values.push(headers.get(i.value));
    }
    return {keys, values};
  }

  doGET(url:string, resolve:ResolveType, reject:RejectType, headers:Headers|null=null) {
      if (!headers) headers = new Headers();
      //if (!headers) headers = {};
      this.addBasicAuthHeader(headers);
      headers.append('Accept', 'application/json');
      //headers.append('pragma', 'no-cache');
      //headers.append('cache-control', 'no-store');
      
    const myRequest = new Request(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      cache: 'no-store',
    });
    
    console.log('REST getting ' + url, this.dumpHeaders(headers));
    fetch(myRequest).then(response => {
        console.log(response);
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            throw new TypeError("Response type not json");
        }
        if (response.status<200 && response.status>=300) {
            throw new Error(`HTTP Error ${response.status}: ${response.statusText}`);
        }
        if (!response.ok) {
            throw new Error(`HTTP Failed ${response.status}: ${response.statusText}`);
        }
        resolve(response.json());

    }).catch(err => {
        reject(err);
    });
  }

  doPOSTData(url: string, resolve: ResolveType, reject: RejectType, data: any, headers: Headers, redirect = true) {
    const myRequest = new Request(url, {
      method: 'POST',
      headers: headers,
      cache: 'no-store',
      mode: 'cors',
      body: data,
    });

    console.log('REST posting ' + url);
    const options:RequestInit = { redirect: redirect ? 'follow' : 'manual' };
    fetch(myRequest, options).then(async (response) => {
      console.log(response);
      if (!redirect && (response.status==303 || response.status==0)) {
        if (response.type === 'opaqueredirect') {
          // The URL was redirected. Unfortunately, due to security reasons, 
          // browsers do not expose the redirected URL in response for 'manual' redirect mode.
          throw new Error('Error request was redirected but the new URL not accessible due to security reasons.');
        } else {
          // The request was not redirected, process the response as usual.
          //console.log(response.url); // This is the final URL after redirection.
          return {response, result:response.url}
        }
      } else {
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new TypeError(`Error response type, code ${response.status}:${response.statusText}`);
        }
        let result = await response.json();
        return { response, result };  
      }
    }).then(res => {
      let { response, result } = res;
      if (!redirect && response.status==303) {
        resolve(result);
        return;
      }
      if (response.status < 200 || response.status >= 300) {
        if (result._status === 'ERR' && result._error) {
          throw new Error(`Error ${result._error.code}: ${result._error.message}`);
        } else {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      resolve(result);
    }).catch(err => {
      reject(err);
    });
  }

  doPOST(url:string, resolve:ResolveType, reject:RejectType, data:{[k:string]:any}, headers:Headers|null=null, auth=true, redirect=true) {
    if (!headers) headers = new Headers();
    if (auth) this.addBasicAuthHeader(headers);
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('Accept', 'application/json');
    //headers.append('pragma', 'no-cache');
    //headers.append('cache-control', 'no-store');
    let body = '';
    console.log("Object.keys():",  data);
    let keys = Object.keys(data);
    for(let key of keys) {
        if (body) body += '&';
        body += `${key}=` + encodeURIComponent(data[key]);
    }
    this.doPOSTData(url, resolve, reject, body, headers, redirect);
  }

  doPOSTJson(url:string, resolve:ResolveType, reject:RejectType, data:string, headers:Headers|null=null, auth=true, redirect=true) {
    if (!headers) headers = new Headers();
    if (auth) this.addBasicAuthHeader(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    this.doPOSTData(url, resolve, reject, data, headers, redirect);
  }

  login(username:string, password:string) {
    this.username = username;
    this.password = password;
    let url = this.getRequestURL('accounts/' + this.username);

    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  register(username:string, password:string, passwordConfirm:string, emailPhone:string, checkCode:string) {
    let url = this.getRequestURL('accounts/register');
    let body:FormValuesType = {username, password, passwordConfirm, emailPhone, checkCode};

    return new Promise<{[key:string]:any}>((resolve, reject) => {
      this.doPOST(url, resolve, reject, body, null, false);
    });
  }

  changePassword(currentpwd:string,newpwd:string,newpwdConfirm:string) {
    let url = this.getRequestURL('accounts/chpw');
    let body:FormValuesType = {currentpwd,newpwd,newpwdConfirm};

    return new Promise<{[key:string]:any}>((resolve, reject) => {
      this.doPOST(url, resolve, reject, body);
    });
  }

  sendRegisterCode(username:string, emailPhone:string) {
    let url = this.getRequestURL('accounts/registercode');
    let body:FormValuesType = {username, emailPhone};

    return new Promise<{[key:string]:any}>((resolve, reject) => {
      this.doPOST(url, resolve, reject, body, null, false);
    });
  }

  sendResetCode(emailPhone:string) {
    let url = this.getRequestURL('accounts/resetcode');
    let body:FormValuesType = {emailPhone};

    return new Promise<{[key:string]:any}>((resolve, reject) => {
      this.doPOST(url, resolve, reject, body, null, false);
    });
  }

  resetPassword(password:string, passwordConfirm:string, emailPhone:string, checkCode:string) {
    let url = this.getRequestURL('accounts/resetpwd');
    let body:FormValuesType = {password, passwordConfirm, emailPhone, checkCode};

    return new Promise<{[key:string]:any}>((resolve, reject) => {
      this.doPOST(url, resolve, reject, body);
    });
  }

  syncEvent(evt:{[k:string]:any}) {
      let url = this.getRequestURL('events');
      return new Promise((resolve, reject) => {
        this.doPOST(url, resolve, reject, evt);
      });  
  }

  getEvents(startTimestamp:number|null=null, stopTimestamp:number|null=null) {
    let url = this.getRequestURL('events?sort=-eventTS&max_results=300');
    let where = null;
    if (startTimestamp && stopTimestamp) {
      where = '{"$and":[{"eventTS":{"$gte":'+startTimestamp+'}},{"eventTS":{"$lte":'+stopTimestamp+'}}]}';
    } else if (startTimestamp) {
      where = '{"eventTS":{"$gte":' + startTimestamp + '}}';
    } else if (stopTimestamp) {
      where = '{"eventTS":{"$lte":' + stopTimestamp + '}}';
    }
    if (where) {
      console.log("CloudEve where:", where);
      url += '&where=' + encodeURIComponent(where);
    }

    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  getTags() {
    const url = this.getRequestURL('tags/' + this.username);
    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  getPayment() {
    const url = this.getRequestURL('payments/' + this.username);
    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  setTags(tags:{[group:string]:string[]}) {
    const url = this.getRequestURL('tags/' + this.username);
    const data = JSON.stringify(tags);
    return new Promise((resolve, reject) => {
      this.doPOSTJson(url, resolve, reject, data);
    });
  }

  checkout(item:string, quantity:number) {
    let url = this.getRequestURL('shopping/checkout');
    return new Promise((resolve, reject) => {
      this.doPOST(url, resolve, reject, {item, quantity }, null, true, true);
    });
  }

  getPayingOrders():Promise<{[k:string]:any}> {
    let url = this.getRequestURL('shopping/order/payings');
    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  getOrders():Promise<{[k:string]:any}> {
    let url = this.getRequestURL('shopping/order/list');
    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  getSubscriptionState():Promise<{[k:string]:any}> {
    let url = this.getRequestURL('shopping/subscription/state');
    return new Promise((resolve, reject) => {
      this.doGET(url, resolve, reject);
    });
  }

  removeSubscription() {
    let url = this.getRequestURL('shopping/subscription/remove');
    return new Promise((resolve, reject) => {
      this.doPOST(url, resolve, reject, {});
    });
  }

  customerPortal(order_id:string|null=null) {
    let url = this.getRequestURL('shopping/customer-portal');
    let data = order_id ? {order_id} : {};
    return new Promise((resolve, reject) => {
      this.doPOST(url, resolve, reject, data);
    });
  }
}

const CloudAPI = new CloudAPIComponent();
export default CloudAPI;
