import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Form, Row, Button, } from 'react-bootstrap';
import CloudAPI from '../CloudAPI';
//import history from '../history';
import {setAccount, } from './reducer';
import {RootState} from '../rootReducer';
import {withUIHelpers, PropsFromUIHelpers} from '../components/hocs';
import * as validators from '../Validators';


const mapState = (state:RootState) => ({});
const mapDispatch = {setAccount, };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

interface State {
    username: string;
    password: string;
    passwordConfirm: string;
    emailPhone: string,
    checkCode: string,
    acceptTOS: boolean,
}

class RegisterPage extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            passwordConfirm: '',
            emailPhone: '',
            checkCode: '',
            acceptTOS: false,
        };
    }

    componentDidMount() {}

    onAcquireCaptcha() {
        if (!validators.validateUsername(this.state.username)) {
            this.props.alert('Form error', 'Invalid user name.');
            return;
        }
        let result = validators.validateEmailPhone(this.state.emailPhone);
        if (!result || result != 'email') {
            this.props.alert('Form error', 'Invalid email address.');
            return;
        }
        this.props.showBusy('Connect cloud', 'Requesting verification code...');
        CloudAPI.sendRegisterCode(this.state.username, this.state.emailPhone).then((result) => {
            this.props.hideBusy();
            this.props.alert('Info', 'Verification code sent successfully.');
        }).catch(err => {
            let msg = CloudAPI.getErrString(err);
            this.props.hideBusy();
            this.props.alert('Cloud error', msg);
        })
    }

    onSubmitForm(e:any) {
        console.log(e);
        e.preventDefault();
        let {username, password, passwordConfirm, emailPhone, checkCode, acceptTOS} = this.state;
        if (!validators.validateUsername(username)) {
            this.props.alert('Error', 'Invalid username.');
            return;
        }
        if (!validators.validatePassword(password)) {
            this.props.alert('Error', 'Invalid password.');
            return;
        }
        if (password !== passwordConfirm) {
            this.props.alert('Error', 'Inputs of password not match.');
            return;
        }
        const v = validators.validateEmailPhone(emailPhone)
        if (!v || v != 'email') {
            this.props.alert('Error', 'Invalid email address.');
            return;            
        }
        if (!validators.validateCaptcha(checkCode)) {
            this.props.alert('Error', 'Invalid verification code.');
            return;
        }
        if (!acceptTOS) {
            this.props.alert('Error', 'To continue, you should accept the Terms of Service.');
            return;
        }
        this.props.showBusy('Busy', 'Signing up litemoment.com');
        CloudAPI.register(username, password, passwordConfirm, emailPhone, checkCode).then((result:any) => {
            console.log(result);
            this.props.hideBusy();

            this.props.alert('Register', 'Congratulations! You are ready to sign in.', () => {
                //history.back();
                this.props.navigate(-1);
            });

        }).catch(err => {
            this.props.hideBusy();
            let msg = CloudAPI.getErrString(err);
            this.props.alert('Cloud error', msg);
        });
    }

    onChangeText(e:any, field:string)  {
        let value:string = (e.target as HTMLInputElement).value;
        if (field === 'username') this.setState({username:value});
        else if (field === 'password') this.setState({password:value});
        else if (field === 'passwordConfirm') this.setState({passwordConfirm:value});
        else if (field === 'emailPhone') this.setState({emailPhone:value});
        else if (field === 'checkCode') this.setState({checkCode:value});
    }

    onChangeCheckbox(e:any, field:string) {
        let value = e.target.checked;
        if (field === 'acceptTOS') this.setState({acceptTOS:value,});
    }

    render() {
        return (
            <div style={{padding:20,}}>
                <div style={{maxWidth:600, minWidth:300, margin:'auto'}}>
                    <h1 style={{padding:20}}>Sign up</h1>
                    <div style={{textAlign:'left',}}>
                        <Form onSubmit={this.onSubmitForm.bind(this)}>
                        <Form.Group controlId="username">
                            <Form.Label>User name</Form.Label>
                            <Form.Control type="text" placeholder="Type user name here"
                                value={this.state.username}
                                onChange={(e:any) => {this.onChangeText(e, 'username');}}
                                />
                            <Form.Text className="text-muted">
                            First character should be letter, only letters or numbers or '_' accepted.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="emailPhone">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="text" placeholder="Email address"
                                value={this.state.emailPhone}
                                onChange={(e:any) => {this.onChangeText(e, 'emailPhone');}}
                                />
                        </Form.Group>
                        <Form.Group controlId="checkCode">
                            <Form.Label>Verification code</Form.Label>
                            <Row>
                                    <Button variant="primary" type="button"
                                        onClick={() => {this.onAcquireCaptcha(); }}>Get now</Button>
                                    <div style={{float:'right', marginLeft:5,}}>
                                        <Form.Control type="text" placeholder="Code from email"
                                            value={this.state.checkCode}
                                            onChange={(e:any) => {this.onChangeText(e, 'checkCode');}}
                                            />
                                    </div>
                            </Row>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Type password here"
                                value={this.state.password}
                                onChange={(e:any) => {this.onChangeText(e, 'password');}}
                                />
                            <Form.Text className="text-muted">
                            At least 6 characters required.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="passwordConfirm">
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control type="password" placeholder="Type password again"
                                value={this.state.passwordConfirm}
                                onChange={(e:any) => {this.onChangeText(e, 'passwordConfirm');}}
                                />
                        </Form.Group>
                        <Form.Group controlId="acceptTOS">
                            <Form.Check type="checkbox" label="Agree the Terms of Service" 
                                checked={this.state.acceptTOS}
                                onChange={(e:any) => {this.onChangeCheckbox(e, 'acceptTOS');}}
                                />
                            <Form.Text>By continuing you indicate that you have read and 
                    agree to the Terms of Service.</Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withUIHelpers(connector(RegisterPage));
