import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './accounts/LoginPage';
import LogoutPage from './accounts/LogoutPage';
import RegisterPage from './accounts/RegisterPage';
import ResetPage from './accounts/ResetPage';
import ResetPasswordPage from './accounts/ResetPasswordPage';
import ViewPage from './campaigns/ViewPage';
import AddPage from './campaigns/AddPage';
import TagPage from './campaigns/TagPage';
import CheckoutPage from './shopping/CheckoutPage';
import CancelPage from './shopping/CancelPage';
import SuccessPage from './shopping/SuccessPage';
import OrderPage from './shopping/OrderPage';
import {RootState} from './rootReducer';
import DialogAlert from './components/DialogAlert';
import DialogBusy from './components/DialogBusy';
import DialogConfirm from './components/DialogConfirm';
import DialogSelect from './components/DialogSelect';
import DialogTextInput from './components/DialogTextInput';
import ToastList from './components/ToastList';
import * as dataUtils from './DataUtils';
import './App.css';


const mapState = (state:RootState) => ({
  dialogAlert: state.app.dialogAlert,
  dialogBusy: state.app.dialogBusy,
  dialogConfirm: state.app.dialogConfirm,
  dialogTextInput: state.app.dialogTextInput,
  dialogSelect: state.app.dialogSelect,
  toastList: state.app.toastList,
});
const mapDispatch = { };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface State {};

class App extends React.Component<Props, State> {
    render() {
    const { dialogAlert: a, dialogBusy: b, dialogConfirm: c, dialogTextInput: t, dialogSelect: s, toastList: tl } = this.props;
    return (
      <div className="App">
        <DialogAlert visible={a.visible} title={a.title} message={a.message} onClose={dataUtils.closeAlert} />
        <DialogBusy visible={b.visible} title={b.title} message={b.message} />
        <DialogConfirm visible={c.visible} title={c.title} message={c.message} onClose={dataUtils.closeConfirm} />
        <DialogTextInput visible={t.visible} title={t.title} value={t.value} onClose={dataUtils.closeTextInput} />
        <DialogSelect visible={s.visible} title={s.title} value={s.value} items={s.items} onClose={dataUtils.closeSelect} />
        <ToastList toasts={tl.toasts} onClose={dataUtils.closeToast} />
        <Router>
          <Routes>
            <Route path="/" element={<AddPage />} />
            <Route path="tag" element={<TagPage />} />
            <Route path="view" element={<ViewPage />} />
            <Route path="signin" element={<LoginPage />} />
            <Route path="signout" element={<LogoutPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="reset" element={<ResetPage />} />
            <Route path="resetpwd" element={<ResetPasswordPage />} />
            <Route path="shopping/checkout" element={<CheckoutPage />} />
            <Route path="shopping/cancel" element={<CancelPage />} />
            <Route path="shopping/success" element={<SuccessPage />} />
            <Route path="shopping/orders" element={<OrderPage />} />
          </Routes>
        </Router>
      </div>
    );
  }

}

//const App: React.FC = () => {}
export default connector(App);
