import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Button, Form, Alert} from 'react-bootstrap';
import {RootState} from '../rootReducer';
import {Tags, setTagGroup} from './reducer';
import AppNav from '../AppNav';
import {PropsFromUIHelpers, withUIHelpers,} from '../components/hocs';
import {MatchEventType, createMatchEvent, MatchEvent} from './reducer';
import * as AppUtils from '../AppUtils';
import CloudAPI from '../CloudAPI';
import * as dataUtils from '../DataUtils';
import '../styles.css';


// https://medium.com/@bryanjenningz/how-to-record-and-play-audio-in-javascript-faa1b2b3e49b


const mapState = (state:RootState) => ({
    tags: state.campaigns.tags,
    tagGroup: state.campaigns.tagGroup,
    account: state.account,
});
const mapDispatch = {setTagGroup};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

interface State {
    tags: Tags,
    tagGroup: string,
    eventText: string,
    homeScore: number,
    guestScore: number,
    fetching: boolean,
}

class AddPage extends React.Component<Props, State> {
    _mounted: boolean;
    constructor(props:Props) {
        super(props);
        this.state = {
            tags: props.tags,
            tagGroup: props.tagGroup,
            eventText: '',
            homeScore: 0,
            guestScore: 0,
            fetching: false,
        };
        this._mounted = false;
    }

    getTagGroup(tags:Tags) {
        let groups = Object.keys(tags);
        if (groups.length === 0) return '';
        groups.sort();
        return groups[0];
    }

    static getDerivedStateFromProps(props:Props, state:State) {
        let stateN:any = {};
        if (!AppUtils.isVariableSame(props.tags, state.tags)) {
            stateN.tags = props.tags;
        }
        if (props.tagGroup !== state.tagGroup) {
            stateN.tagGroup = props.tagGroup;
        }
        return stateN;
    }

    componentDidMount() {
        this._mounted = true;
        if (this.props.account.userid)
            this.loadCloudData();
    }

    componentWillUnmount() { this._mounted = false; }

    async loadCloudData() {
        this.setState({fetching: true});
        try {
            await this.loadTodayEvents(false); 
            await dataUtils.fetchTags(false);  
        } finally {
            if (this._mounted) this.setState({fetching: false});
        }
    }

    async loadTodayEvents(modal=false, toast=false) {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        let todayTS = Math.floor(today.getTime()/1000);
        modal && dataUtils.showBusy('Add event', 'Loading events of today...');
        try {
            const result:any = await CloudAPI.getEvents(todayTS);
            modal && dataUtils.closeBusy();
            console.log(result);
            let events:MatchEvent[] = [];
            for (let item of result['_items']) {
                let ev = item as MatchEvent;
                events.push(ev);
            }
            AppUtils.sortObjectsByProp(events, "eventTS", true);
            let homeScore = 0;
            let guestScore = 0;
            for (let ev of events) {
                if (ev.eventType === 'HADD') homeScore++;
                else if (ev.eventType === 'HSUB') homeScore--;
                else if (ev.eventType === 'GADD') guestScore++;
                else if (ev.eventType === 'GSUB') guestScore--;
                else if (ev.eventType === 'RESET') { homeScore = 0; guestScore = 0; }
            }
            if (this.state.homeScore !== homeScore || this.state.guestScore !== guestScore) {
                this.setState({homeScore, guestScore});
            }
            return true;

        } catch(err) {
            modal && dataUtils.closeBusy();
            let msg = CloudAPI.getErrString(err);
            dataUtils.showToast('Add event', 'Loading error: ' + msg);
            return false;
        }
    }

    onEventTextChanged(text:string) { this.setState({eventText: text}); }
    onSendEventText() {
        if (!this.state.eventText || this.state.eventText.length < 1) {
            alert(`Text message too short to send.`);
            return;
        }
        this.onMatchEvent('TNOTE', this.state.eventText);
        this.setState({eventText:''});
    }

    onMatchEvent(eventType:MatchEventType, eventData:string|null=null) {
        let ev:MatchEvent = createMatchEvent({eventType,});
        if (eventData) ev['eventData'] = eventData;
        dataUtils.showBusy('Add event', 'Saving event to cloud...');
        CloudAPI.syncEvent(ev).then((result) => {
            console.log(result);
            dataUtils.closeBusy();
            dataUtils.showToast('Add event', `Event ${eventType} uploaded`);
            let {homeScore, guestScore} = this.state;
            if (ev.eventType === 'HADD') homeScore++;
            else if (ev.eventType === 'HSUB') homeScore--;
            else if (ev.eventType === 'GADD') guestScore++;
            else if (ev.eventType === 'GSUB') guestScore--;
            else if (ev.eventType === 'RESET') { homeScore = 0; guestScore = 0; }
            if (this.state.homeScore !== homeScore || this.state.guestScore !== guestScore) {
                this.setState({homeScore, guestScore});
            }
            return true;

        }).catch(err => {
            dataUtils.closeBusy();
            let msg = CloudAPI.getErrString(err);
            dataUtils.showAlert('Add event', 'Cloud error: ' + msg);
            return false;
        })
    }

    onChangeGroup() {
        let groups = Object.keys(this.state.tags);
        groups.sort();
        const items = groups.map(g => ({title:g, value:g}));
        let group = this.state.tagGroup;
        if (!group && groups.length>0) group = groups[0];
        dataUtils.showSelect('Change tag group', group, items, (action, value) => {
            if (action) {
                this.props.setTagGroup(value);
            }
        })
    }

    render() {
        let scoreStyleHome = {
            height:160,
            fontSize:'60px', 
            backgroundColor:'lightred',
            color: 'white',
            borderWidth: 0,
            margin:'0px',
        };
        let scoreStyleGuest = {
            height:160,
            fontSize:'60px', 
            backgroundColor:'lightblue',
            color: 'white',
            borderWidth: 0,
            margin:'0px',
        };
        let {homeScore, guestScore, tags, tagGroup} = this.state;
        
        let userTags:string[] = [];
        if (!tagGroup || !tags[tagGroup]) tagGroup = this.getTagGroup(tags);
        if (tagGroup && tags[tagGroup]) userTags = tags[tagGroup].slice();
        console.log('userTags:', userTags);
        userTags.sort();

        const sysButtons:[string, MatchEventType, string|null][] = [
            // ['Host+', 'HADD', null], 
            // ['Guest+', 'GADD', null], 
            ['Host-', 'HSUB', null], 
            ['Guest-', 'GSUB', null], 
            ['Var', 'VAR', null],
            ['Lite', 'LITE', null],
        ];
        const userButtons:[string, MatchEventType, string|null][] = userTags.map(evData => ['T:' + evData, 'TAG', evData]);
        const allButtons:[string, MatchEventType, string|null][] = sysButtons.concat(userButtons);

        return (
            <div>
                <AppNav active="/"/>
                <div className="container">
                    <div className="flex-row title-row">
                        <div className="row-col-auto">
                            Add Events{tagGroup ? ` (Group: ${tagGroup})`:''}
                            {this.state.fetching && (<img alt="Loading" src="/loading.gif" width={24} height={24}/>)}
                        </div>
                        <div>
                            <Button variant="secondary" size="lg" className="action-button" onClick={() => {this.onChangeGroup()}}>Groups</Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" size="lg" className="action-button" onClick={() => {this.onMatchEvent('RESET')}}>Reset</Button>
                        </div>
                    </div>
                    <div className="row-seperator"/>
                    <div className="flex-row-even">
                        <div className="row-col-auto" onClick={()=>{this.onMatchEvent('HADD', null);}}>
                            <Alert style={scoreStyleHome} variant="primary">{homeScore===null ? '':homeScore}</Alert>
                        </div>
                        <div className="col-seperator"/>
                        <div className="row-col-auto" onClick={()=>{this.onMatchEvent('GADD', null);}}>
                            <Alert style={scoreStyleGuest} variant="primary">{guestScore===null ? '':guestScore}</Alert>
                        </div>
                    </div>
                    <div className="event-buttons">
                        {
                            allButtons.map((b, idx) => {
                                return (
                                    <Button key={`allTags${idx}`} className="event-button" variant="primary" size="lg" 
                                    onClick={()=>{this.onMatchEvent(b[1], b[2]);}}
                                    >{b[0]}</Button>
                                )
                            })
                        }
                    </div>
                    <div className="flex-row">
                        <div className="row-col-auto">
                            <Form.Control
                                    type="text" 
                                    size="lg"
                                    placeholder="Type text message"
                                    value={this.state.eventText}
                                    onChange={(ev:any) => {this.onEventTextChanged(ev.target.value);}}
                                    />
                        </div>
                        <div className="col-seperator"/>
                        <div className="row-col-right">
                            <Button variant="primary" size="lg" className="action-button" 
                                    style={{width:100}}
                                    onClick={()=>{ this.onSendEventText(); }}
                                    >Send</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withUIHelpers(connector(AddPage));
