export function validateUsername(username:string) {
    if (username.length<3) return false;
    if (/^[a-zA-Z][a-zA-Z0-9_]+$/.test(username)) return true;
    return false;
}

export function validatePassword(password:string) {
    if (password.length<6) return false;
    return true;
}

export function validateEmailPhone(emailPhone:string) {
    if (emailPhone.length<4) return false;
    if (/^.+@.+$/.test(emailPhone)) return 'email';
    if (/^[-+ 0-9]+$/.test(emailPhone)) return 'phone';
    return false;
}

export function validateCaptcha(captcha:string) {
    return (/^[0-9]{6,6}$/.test(captcha));
}
