import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Account {
    userid?: string,
    username: string,
    password: string,
    email?: string,
    phone?: string,
    createTS?: number,
    updateTS?: number,
}

export function createAccount(init:Partial<Account>={}) {
    let account = {
        userid: '',
        username: '',
        password: '',
        email: '',
        phone: '',
        createTS: 0,
        updateTS: 0,
    };
    Object.assign(account, init);
    return account;
}

let initialState:Account = createAccount();

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount(state, action: PayloadAction<Account>) {
      const { userid, username, password } = action.payload;
      state.userid = userid;
      state.username = username;
      state.password = password;
    },
  }
})

export const {
    setAccount,
} = accountSlice.actions

export default accountSlice.reducer
