import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {DialogAlertProps} from './components/DialogAlert';
import {DialogBusyProps} from './components/DialogBusy';
import {DialogConfirmProps} from './components/DialogConfirm';
import {DialogSelectProps} from './components/DialogSelect';
import {DialogTextInputProps} from './components/DialogTextInput';
import {ToastProps, ToastListProps} from './components/ToastList';

export interface SubscriptionState {
    id: string,
    uid: string,
    active: boolean,
    startTS: number,
    endTS: number,
    cancelTS: number,
    customid: string,
    subscribeid: string,
    invoiceid: string,
    state: number,
}

export interface AppState {
    // To exclude mutliple fields, use '|', e.g. onClose|onOpen
    dialogAlert: Omit<DialogAlertProps, 'onClose'>,
    dialogBusy: DialogBusyProps,
    dialogConfirm: Omit<DialogConfirmProps, 'onClose'>,
    dialogSelect: Omit<DialogSelectProps, 'onClose'>,
    dialogTextInput: Omit<DialogTextInputProps, 'onClose'>,
    toastList:  Omit<ToastListProps, 'onClose'>,
    payment?: SubscriptionState,
}

const initialState:AppState = {
    dialogAlert: {visible:false, title:'', message:''},
    dialogBusy: {visible:false, title:'', message:''},
    dialogConfirm: {visible:false, title:'', message:''},
    dialogSelect: {visible:false, title:'', items:[], value:''},
    dialogTextInput: {visible:false, title:'', value:''},
    toastList: {toasts:[],},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDialogAlert(state, action: PayloadAction<DialogAlertProps>) {
      state.dialogAlert = action.payload;
    },
    setDialogBusy(state, action: PayloadAction<DialogBusyProps>) {
        state.dialogBusy = action.payload;
    },
    setDialogConfirm(state, action: PayloadAction<DialogConfirmProps>) {
        state.dialogConfirm = action.payload;
    },
    setDialogSelect(state, action: PayloadAction<DialogSelectProps>) {
        state.dialogSelect = action.payload;
    },
    setDialogTextInput(state, action: PayloadAction<DialogTextInputProps>) {
        state.dialogTextInput = action.payload;
    },
    setToastList(state, action: PayloadAction<ToastListProps>) {
        state.toastList = action.payload;
    },  
    addToast(state, action: PayloadAction<ToastProps>) {
        state.toastList.toasts.push(action.payload);
    },
    setPayment(state, action: PayloadAction<SubscriptionState>) {
        state.payment = action.payload;
    }
    }
})

export const {
    setDialogAlert,
    setDialogBusy,
    setDialogConfirm,
    setDialogSelect,
    setDialogTextInput,
    setToastList,
    addToast,
    setPayment,
} = appSlice.actions

export default appSlice.reducer
