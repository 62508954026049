import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Button, } from 'react-bootstrap';
// https://fontawesome.com/icons?d=gallery&p=3&s=brands,solid&m=free
import { faPlusCircle, faTimesCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../rootReducer';
import {Tags, setTags} from './reducer';
import AppNav from '../AppNav';
import Const from '../Consts';
import TextButton from '../components/TextButton';
import '../styles.css';
import * as AppUtils from '../AppUtils';
import * as dataUtils from '../DataUtils';


const mapState = (state:RootState) => ({
    tags: state.campaigns.tags,
    payment: state.app.payment,
});
const mapDispatch = { setTags };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface State {
    tags: Tags,
    fetching: boolean,
};

const TagGroupView = (
    props:{
        group:string, 
        items:string[],
        onAddTag: (group:string)=>void,
        onDeleteTag: (group:string, tag:string)=>void,
        onChangeGroup: (group:string)=>void,
        onDeleteGroup: (group:string)=>void,
    }) => {
    return (
        <div>
            <div>
                <span>Tag group:</span> <b>{props.group}</b>
                &nbsp;
                <TextButton text={'Add tag'} icon={faPlusCircle} onClick={() => {props.onAddTag(props.group);}}/>
                &nbsp;|&nbsp;
                <TextButton text={'Change'} icon={faPen} onClick={() => {props.onChangeGroup(props.group);}}/>
                &nbsp;|&nbsp;
                <TextButton text={'Delete'} icon={faTimesCircle} onClick={() => {props.onDeleteGroup(props.group);}}/>
            </div>
            <ul>
                {
                props.items.map((tag,idx) => {
                    return (<li key={`tagGroup${props.group}Item${idx}`}>
                        {tag}
                        &nbsp;
                        <TextButton text={'Delete'} icon={faTimesCircle} onClick={() => {props.onDeleteTag(props.group, tag);}}/>
                    </li>);
                })
                }
            </ul>
        </div>
    );
}

class TagPage extends React.Component<Props, State> {
    _mounted: boolean;
    constructor(props:Props) {
        super(props);

        this.state = {
            fetching: false,
            tags: props.tags,
        };
        this._mounted = false;
    }

    static getDerivedStateFromProps(props:Props, state:State) {
        if (!AppUtils.isVariableSame(props.tags, state.tags)) {
            return {tags:props.tags};
        }
        return null;
    }

    componentDidMount() { this._mounted = true; this.loadCloudTags(); }

    componentWillUnmount() { this._mounted = false; }

    async loadCloudTags() {
        try {
            this.setState({fetching: true});
            await this.onFetch(false, false);
            await dataUtils.fetchPayment(false, false);
        } finally {
            this._mounted && this.setState({fetching: false});
        }
    }

    checkTagGroup(value:string) {
        value = value ? value.trim():'';
        if (value.length < 1) {
            dataUtils.showAlert('Error', 'Text of tag group too short.');
            return '';
        }
        return value;
    }

    checkTag(value:string) {
        value = value ? value.trim():'';
        if (value.length < 1) {
            dataUtils.showAlert('Error', 'Text of tag too short.');
            return '';
        }
        return value;
    }

    onAddGroup() {
        dataUtils.showTextInput('Add group', '', (action, value) => {
            if (action) {
                value = this.checkTagGroup(value);
                if (value.length === 0) return;
                const tags = this.state.tags;
                if (tags[value]) {
                    dataUtils.showAlert('Error', 'Tag group already exists.');
                } else {
                    let tagsN = {...tags};
                    tagsN[value] = [];
                    this.props.setTags(tagsN);
                    setTimeout(() => { this.onSave(); }, 50);
                }
            }
        })
    }

    onChangeGroup(group:string) {
        dataUtils.showTextInput('Change group', group, (action, value) => {
            if (action) {
                value = this.checkTagGroup(value);
                if (value.length === 0) return;
                const tags = this.state.tags;
                let tagsN = {...tags};
                const groupTags = tags[group];
                delete tagsN[group];
                tagsN[value] = groupTags;
                this.props.setTags(tagsN);
                setTimeout(() => { this.onSave(); }, 50);
            }
        })
    }

    onDeleteGroup(group:string) {
        dataUtils.showConfirm('Delete group', 'Tag group and all its tags will be deleted. Are you sure?', (action) => {
            if (action) {
                const tags = this.state.tags;
                let tagsN = {...tags};
                delete tagsN[group];
                this.props.setTags(tagsN);
                setTimeout(() => { this.onSave(); }, 50);
            }
        })
    }

    getNumTags() {
        let tagTexts:{[text:string]:boolean} = {};
        for (let group of Object.keys(this.state.tags)) {
            for (let text of this.state.tags[group]) {
                tagTexts[text] = true;
            }
        }
        return Object.keys(tagTexts).length;
    }

    onAddTag(group:string) {
        if (this.getNumTags() >= Const.MAX_FREE_TAGS && !this.isVIP()) {
            dataUtils.showAlert('Add tag', 'Number of tags exceeds limit.');
            return;
        }

        dataUtils.showTextInput('Add tag', '', (action, value) => {
            if (action) {
                value = this.checkTag(value);
                if (value.length === 0) return;
                const tags = this.state.tags;
                let groupTags = tags[group];
                if (!groupTags) groupTags = [];
                groupTags = groupTags.filter(t => t !== value);
                groupTags.push(value);
                let tagsN = {...tags};
                tagsN[group] = groupTags;
                this.props.setTags(tagsN);
                setTimeout(() => { this.onSave(); }, 50);
            }
        })
    }

    onDeleteTag(group:string, tag:string) {
        dataUtils.showConfirm('Delete tag', 'Tag will be deleted. Are you sure?', (action) => {
            if (action) {
                const tags = this.state.tags;
                let groupTags = tags[group];
                groupTags = groupTags.filter(t => t !== tag);
                let tagsN = {...tags};
                tagsN[group] = groupTags;
                this.props.setTags(tagsN);
                setTimeout(() => { this.onSave(); }, 50);
            }
        })
    }

    async onFetch(modal=true, toast=true) { return await dataUtils.fetchTags(modal, toast); }

    async onSave() { await dataUtils.saveTags(this.state.tags); }

    isVIP() { return (this.props.payment && this.props.payment.active); }

    render() {
        const tagGroups = Object.keys(this.state.tags);
        const isVIP = this.isVIP();
        const vipText = isVIP ? '(VIP)':'';
        const limitText = isVIP ? 'unlimited':Const.MAX_FREE_TAGS;
        const numTags = this.getNumTags();
        return (
            <div>
                <AppNav active="/tag"/>
                <div className="container">
                    <div className="flex-row title-row">
                        <div className="row-col-auto">
                                Cloud Tags{vipText}
                                {this.state.fetching && (<img alt="Loading" src="loading.gif" width={24} height={24}/>)}
                        </div>
                        <div>
                            <Button size="lg" className="action-button" onClick={() => { this.onFetch(true, true); }}>Refresh</Button>
                            <span>&nbsp;&nbsp;</span>
                            <Button size="lg" className="action-button" onClick={() => { this.onSave(); }}>Save</Button>
                        </div>
                    </div>
                    <div className="row-seperator"/>
                    <div className="flex-row">
                        <div className="row-col-auto">
                            <span>Found {numTags} tag{numTags>1 ? 's':''}(Limit {limitText})</span>
                        </div>
                        <div>
                            <TextButton text={'Add group'} icon={faPlusCircle} onClick={() => {this.onAddGroup();}}/>
                        </div>
                    </div>
                    <div className="row-seperator"/>
                    <div>
                        {tagGroups.map(
                            (group, idx) => {
                                const items = this.state.tags[group];
                                return (<TagGroupView key={`tagGroup${idx}`} group={group} items={items}
                                    onChangeGroup={this.onChangeGroup.bind(this)}
                                    onDeleteGroup={this.onDeleteGroup.bind(this)}
                                    onAddTag={this.onAddTag.bind(this)}
                                    onDeleteTag={this.onDeleteTag.bind(this)}
                                />);
                            }
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connector(TagPage);
